















































































































import { Empresa } from '@/core/models/geral';
import { OrdemServico } from '@/core/models/residuo';
import { EmpresaService, VeiculoService, MotoristaService } from '@/core/services/geral';
import { OrdemServicoService } from '@/core/services/residuo';
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class DetalharOrdemServico extends Vue {

  empresa: Empresa = new Empresa();
  item: OrdemServico = new OrdemServico();
  sessionApp: any;
  nomeLogo: string = '1.png';
  parametro: any;
  overlay: boolean = false;

  mounted(){

    const id: number = Number(this.$route.params.id);
    const service = new OrdemServicoService();
    this.overlay = true;
    service.ObterPorId(id)
      .then(
        res => {
          this.item = res.data;
        },
        err => {
          this.$swal('Aviso', err.message, 'error')
        }
      ).finally(()=>{
        const empresaService = new EmpresaService();
        this.overlay = false;
        empresaService.ObterPorId(this.item.empresaId)
          .then(
            res => {
              this.empresa = res.data;
            },
            err => {
              this.$swal('Aviso', err.message, 'error')
            }
          );
      });
  }

  Imprimir(){
    window.print();
  }

  Fechar(){
    window.close();
  }

}

